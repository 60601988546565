@use "~@givesafe/design-system-react/dist/styles/colors/palette" as p;
@use "~@givesafe/design-system-react/dist/styles/colors/color-functions" as c;

.container {
  min-height: 100vh;
  min-width: 100%;
  background: p.$LightGrey03;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.back {
  align-self: flex-start;
}

.card {
  width: 100%;
  max-width: 500px;
  max-height: 100vh;
  padding: 30px;
  color: c.color("text", "light");
  background: c.color("background", "dark-alt-1");
}

.cardWrapper {
  position: relative;
}

.backButton {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translate(-120%, -50%);

  @media only screen and (max-width: 768px) {
    top: 100%;
    // bottom: 100px;
    transform: translate(0%, 20%);
  }
}
