.buttonGroupContainer {
    width: 75%;
    margin:auto;
    margin-top: 3rem;
    font-size: 20px;
    font-weight: 200;
}

.buttonGroup  {
    max-height: 550px;
    width: 100%;
    margin: auto;
    overflow: auto;
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
  
    & > * {
      min-height: min-content;
      margin-bottom: .5rem;
    }
}