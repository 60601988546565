@use "~@givesafe/design-system-react/dist/styles/colors/color-functions" as c;
@use "~@givesafe/design-system-react/dist/styles/fonts/font-functions" as f;

.loginContainer {
  height: 100vh;
  width: 100vw;

  background-color: c.color("background", "dark");
  color: c.color("text", "light");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
  @include f.font-setting("h0");
  margin-bottom: 20px;
}

.loginDiv {
  padding: 60px 0px 140px 0px;
  width: 100%;
  margin: auto;
}

.loginForm > *:not(:first-child) {
  margin-top: 20px;
}

.credentialsInputDiv {
  margin: auto;
  width: 75%;
}

@media only screen and (max-width: 767px) {
  .credentialsInputDiv {
    margin: auto;
    width: 100%;
  }
  .loginDiv {
    padding: 30px 40px;
  }
}

.input {
  width: 215px;
  margin-top: 10px;
  border: 2px solid rgb(161, 158, 214);
  padding: 14px;
  border-radius: 10px;
}

.errorInput {
  width: 215px;
  margin-top: 10px;
  border: 3px solid rgb(245, 87, 122);
  padding: 14px;
  border-radius: 10px;
}

.pin {
  border: 2px solid rgb(161, 158, 214);
  background: white;
  border-radius: 10px;
}

.errorPin {
  border: 2px solid rgb(245, 87, 122);
  background: white;
  border-radius: 10px;
}

.date {
  border-radius: 10px !important;
  background: white;
  border: 2px solid rgb(161, 158, 214);
}

.date > input {
  padding: 13px 13px;
}

.errorDate {
  border-radius: 10px !important;
  background: white;
  border: 3px solid rgb(245, 87, 122);
}


.root {
  background-color: c.color("background", "dark");
  color: c.color("text", "light");
  overflow: auto;
  height: 100vh;
  width: 100vw;
  max-width: 100%;

  background: #E3E3FA;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Manrope, sans-serif ;

  & * {
    box-sizing: border-box;

  }
}

.login {
  display: flex;
  overflow: hidden;
  
  max-height: 800px;
  max-width: 1200px;
  bottom: 35%;
  
  background: #595985;
  
  box-shadow: 0 20px 46px 15px #00000050;
  
  color: color("text","light");
  
  @media only screen and (max-width: 1000px) {
    width: 95%;
    height: 95%;
    border-radius: 30px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    max-height: unset;
    border-radius: 0px;
  }

  @media only screen and (min-width: 1000px) {
    width: 80%;
    height: 90%;
    border-radius: 30px;
  }


}

.loginDivVideoWrapper {
  position: relative;
  overflow: hidden;

  width: 100%;
}

.loginVideoDiv {
  position: relative;

  height: 100%;
  max-height: 100%;
  width: 100%;


  display: flex;
  align-items: center;
  overflow: hidden;

  z-index: 100;
}

.loginVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 35%;
}

.opacity {
 position: absolute;
  height: 100%;
  width: 100%;

  background-color: #7378e8;
  opacity: 0.3;
}

.logo {
  position: absolute;
  z-index: 100;
  left: 50%;
  bottom: 35%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mobileLogo {
  display: none;
  flex-direction: column;
  align-items: center;
  width: max-content;
}

.logoCaption {
  font-size: 0.80rem;
  text-align: center;
  margin-top: 10px;
  color: white;
}

.loginFormDiv {
  position: relative;
  overflow-y: scroll;
  z-index: 1;
  margin-right: 5px;
}

/* Style the scrollbar */
.loginFormDiv::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.loginFormDiv::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #DCDCDC99;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

/* Add padding to the right of the scrollbar */
.loginFormDiv::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* Adjust the width to match the padding-right value */
  height: 100%;
  z-index: -1; /* Ensure it's behind the scrollbar */
}


.greetingText {
  padding: 30px 40px;
  width: 100%;
  margin: auto;
  margin-top: #{"min(30%, 60px)"};
}

.welcomeBack {
  font-family: "Manrope";
  font-size: 34px;
  font-weight: 800;
  // line-height: 55.13px;
  text-align: left;
  margin-bottom: 1rem;
}

.divline {
  border: 1.5px solid #FFFFFF66;
  width: 75%;
  margin: 1.5rem auto;
}

.divline2 {
  border: 1.5px solid #FFFFFF66;
  width: 100%;
  margin: 1.5rem auto;
}

.partnerSignIn {
  font-family: "Manrope";
  font-size: 20px;
  font-weight: lighter;
  // line-height: 51.91px;
  text-align: left;
}

.inputGroup {
  margin-block: 10px;
}

.error {
  color: #ef5350;
  padding: 10px;
  text-align: left;
  font-size: 16px;
  margin: auto;
  width: 75%;
}

.quickAction {
  cursor: pointer;

  border: none;
  border-radius: 1000px;
  color: #7378e8;
  cursor: pointer;
  font-size: 1.2rem;
  height: 2em;
  line-height: 2em;
  overflow: hidden;
  text-align: center;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotPasswordDiv { 
  margin: auto;
  width: 75%;
  display: grid;
  grid-template-columns: auto auto auto;
}

.forgotPasswordTitle {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  margin: 0 2px 0;
}

.forgotPasswordButton {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  color: #fff;
  line-height: 1.25rem;
  padding: 0.2em;
  background: none;
  text-decoration: underline;
  text-align: left;
}

.currentCityDiv {
  margin: auto;
  width: 75%;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}


@media only screen and (max-width: 767px) {
  .root {
    align-items: unset;
    background: #595985;
    height: 100vh;
  }

  .forgotPasswordDiv { 
    width: 100%;
  }

  .error {
    width: 100%;
  }
  
  .login {
    position: fixed;
    top:0;
    display: block;
    overflow: auto;
  }

  .greetingText {
    margin-top: 0px;
  }

  .loginDivVideoWrapper {
    position: absolute;
    top: 0;
    height: 100vh;
  }

  .loginFormDiv {
    position: absolute;
    z-index: 10;
    min-height: 100vh;
    padding-top: 130px;
  }

  .logo {
    display: none;
  }

  .mobileLogo {
    display: flex;
    position: absolute;
    z-index: 200;
    transform: translate(-50%, 0%);
    left: 50%;
  }
}

.loginOptionsDiv, .contactInputsDiv {
  padding: 30px 40px;
  width: 100%;
  margin: auto;
  // margin-top: min(30%, 60px);
}

.optionsDiv {
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactSelect {
  display: block;
  width: 210px;
  background-color: white;
}

.contactSelectTitle {
  display: block;
}

.success {
  color: #7378e8;
}

.loginBtn{
  color: #fbfbff;
  background-color: #7378e8;
}

.selectDiv {
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.inputOptionDiv {
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactInput {
  border-radius: 10px !important;
  background: white;
  border: 2px solid rgb(161, 158, 214);
  width: 200px;
  padding: 10px;
}